import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";

import "../css/style.css";
import "../css/sharan-style.css";
import { Helmet } from "react-helmet";
import ProgramInfoCommon from "../components/program-listing-info-common-ug";
import programListData from '../../programListSummary.json';

import ArrowRightSolidIcon from '../images/icons/arrow-right-solid.svg';
import ArrowLeftSolidIcon from '../images/icons/arrow-left-solid.svg';

const _ = require(`lodash`);
var Country, DegreeType, categoryname;
function getParameterByName(name) {
	name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
	var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
		results;
	if (typeof window !== `undefined`) {
		results = regex.exec(window.location.search);
	}
	return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}
function scrollToTop() {
	//document.querySelector('#result-course-finder').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
	if (window.scrollY > 200) {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	  }		
}


function multiSort(array, sortObject = {}) { 
	const sortKeys = Object.keys(sortObject);

	// Return array if no sort object is supplied.
	if (!sortKeys.length) {
		return array;
	}

	// Change the values of the sortObject keys to -1, 0, or 1.
	for (let key in sortObject) {
		sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1 : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
	}

	const keySort = (a, b, direction) => {
		direction = direction !== null ? direction : 1;

		if (a === b) { // If the values are the same, do not switch positions.
			return 0;
		}

		// If b > a, multiply by -1 to get the reverse direction.
		return a > b ? direction : -1 * direction;
	};

	return array.sort((a, b) => {
		let sorted = 0;
		let index = 0;

		// Loop until sorted (-1 or 1) or until the sort keys have been processed.
		while (sorted === 0 && index < sortKeys.length) {
			const key = sortKeys[index];

			if (key) {
				const direction = sortObject[key];

				sorted = keySort(a[key], b[key], direction);
				index++;
			}
		}

		return sorted;
	});
}

class Programlisting extends Component {
	constructor(props) {
		super(props);

		//var data = this.props.data.allWordpressWpPogramlist.edges;
		const data = programListData.programlist;

		var newData = [];
		var featuredData = [];
		var generalData = [];
		var listCountry = [];
		var listCategory = [];
		var listDegree = [];


		//console.log("programlistData->"+JSON.stringify(data)); 

		for(let i = 0; i < data.length; i++) {
			const node= data[i];
			//console.log("programlistData->"+JSON.stringify(node)); 
		
		  }




		for(let i = 0; i < data.length; i++) {
			const node= data[i];
			
			//console.log("programlistData->"+JSON.stringify(node)); 
			var title = node.title.rendered;
			var wordpressid = node.id;
			var slug = node.slug;

			var UniversityImage = node.featured_media;
			// var UniversityImage = node.acf.university_profile_picture.source_url;
			var UniversityRank = node.acf.university_ranking;
			var UniversityLogo = node.acf.university_logo;
			//var UniversityLogo = "";
			var PogramDuration = node.acf.program_dudation;
			var Country = node.acf.country.trim();
			var UniversityName = node.acf.list_of_university;
			var UniversityNameUrl = node.acf.name_of_university;
			var PgmName = node.acf.program_name_in_url;
			var ShortEligibility = node.acf.short_eligibility;
			var YouSave = node.acf.you_save;
			var Term1Info = node.acf.term_1;
			var Term2Info = node.acf.term_2;
			var Term3Info = node.acf.year_2_and_3;
			
			var Term3Info = node.acf.year_2_and_3;
			var Featured = node.acf.featured;
			var FeaturedOrder = node.acf.featured_order;
			var DegreeType = node.acf.degree_type;
			var DirectEligibility = node.direct_eligibility_short;
			var PgDetailForListing = node.acf.pg_detail_for_listing;
			var Term1Title = node.acf.term_1_title;
			var Term2Title = node.acf.term_2_title;
			var Term3Title = node.acf.term_3_title; 
			var third_party_link = node.acf.third_party_link; 
			
			
			// var locationlistId = node.acf.locationlist;
			// var GraduationlistId = node.graduationlist;
			var categoryname = node.acf.study_area.trim();
			var eachData = {
				title: title,
				wordpressid: wordpressid,
				slug: slug,
				UniversityImage: UniversityImage,
				UniversityRank: UniversityRank,
				UniversityLogo: UniversityLogo,
				PogramDuration: PogramDuration,
				Country: Country,
				UniversityName: UniversityName,
				UniversityNameUrl: UniversityNameUrl,
				PgmName: PgmName,
				ShortEligibility: ShortEligibility,
				DirectEligibility: DirectEligibility,
				PgDetailForListing: PgDetailForListing,
				YouSave: YouSave,
				Term1Info: Term1Info,
				Term2Info: Term2Info,
				Term3Info: Term3Info,
				Featured:Featured,
				FeaturedOrder:FeaturedOrder,
				DegreeType: DegreeType,
				categoryname: categoryname,
				Term1Title: Term1Title,
				Term2Title: Term2Title,
				Term3Title: Term3Title,
				third_party_link: third_party_link,
				//  categoryId: categoryId,
				//  GraduationlistId: GraduationlistId,
			};
			if(node.acf.featured == true) {
				featuredData.push(eachData);
			} else 
			{
				generalData.push(eachData);
			}
				
			
			
			


			if(listCountry.indexOf(Country) === -1 && DegreeType != null) {
				listCountry.push(Country);
			}
			listCountry = listCountry.sort();
			if(listDegree.indexOf(DegreeType) === -1 && DegreeType != null) {
				listDegree.push(DegreeType);
			}
			


			var categoryMaster = categoryname; 

			if(listCategory.indexOf(categoryMaster) === -1 && categoryname != null) {
			
				listCategory.push(categoryMaster);
			}

		
	
		}
			// console.log("check",eachData);
		
	

			


		featuredData.sort((a, b) => {
			if (a.FeaturedOrder === null && b.FeaturedOrder !== null) {
			  return 1;
			}
			if (a.FeaturedOrder !== null && b.FeaturedOrder === null) {
			  return -1;
			}
			if (a.FeaturedOrder === null && b.FeaturedOrder === null) {
			  return 0;
			}
			return a.FeaturedOrder - b.FeaturedOrder;
		  });
		  


		newData = featuredData.concat(generalData);

		listCategory = listCategory.sort();
		var mainCat = "";
		var subCat = [];
		var catArray = [];
		listCategory.forEach((category) => {
			const mySplit = category.split("||");
			
			if(mainCat == "" ||  mainCat != mySplit[0]) {

				var key = mySplit[0];    
				

				mainCat = mySplit[0];
				subCat.push(mySplit[1]);
				catArray.push({[key]: subCat});
				//catArray.push(subCat);
				subCat = [];
			}else if(mainCat == mySplit[0]) {
				subCat.push(mySplit[1]);
				//console.log("Degree->"+JSON.stringify(subCat))
			}
			
			
		});

	/*	for (var category in catArray) {
			var subcategory = catArray[category];
			//console.log("Cate->",category, subcategory);
		}
	
		var mainCat = "";
		var subCat = "";
		catArray.forEach((category) => {
			const mySplit = category.split("||");
			
			if(mainCat == "" ||  mainCat != mySplit[0]) {
				mainCat = mySplit[0];
				//console.log("mainCat->"+mainCat);
			}else if(mainCat == mySplit[0]) {
				subCat = mySplit[1];
				//console.log("--submainCat->"+subCat);
			}
			
			
		});
	

		//console.log("DegreeType->"+JSON.stringify(catArray));


*/

		this.state = {
			data: newData,
			listCategory: catArray,
			listCountry: listCountry,
			listDegree: listDegree,
			Country: "all",
			DegreeType: "all",
			categoryname: "all",
			FilterdList: null,
			sortValue: null,
			sortValue1: null,
			sortValue2: null,
			scrolled: false,
			programList: true,
			loading: false,
				currentGroup: 1,
				totalGroup: 1,
		};
		// this.countryprogramfiltering = this.countryprogramfiltering.bind(this)
		// this.graduationlistfiltering = this.graduationlistfiltering.bind(this)
	}

	componentDidMount() {
		// console.log("USA Query", usaQuery);
		this.setState({ loading: true });
		Country = getParameterByName("Country");
		categoryname = getParameterByName("categoryname");
		DegreeType = getParameterByName("DegreeType");

		// Get the input elements
const checkboxesDegree = document.querySelectorAll('ul.list-style-07.filter-category input[type="checkbox"]');
const checkboxesCountry = document.querySelectorAll('ul.list-style-07.margin-30px-bottom input[type="checkbox"]');


// Iterate through the checkboxes and compare the values
checkboxesDegree.forEach(checkbox => {
  const checkboxValue = checkbox.value;
  
  // Check if the checkbox value matches the DegreeType parameter
  if (DegreeType === checkboxValue) {
    checkbox.checked = true; // Check the checkbox
    //console.log(`Matching item found: ${checkboxValue}`);
  }
});

// Iterate through the checkboxes and compare the values
checkboxesCountry.forEach(checkbox => {
	const checkboxValue = checkbox.value;
	
	// Check if the checkbox value matches the Country parameter
	if (Country === checkboxValue) {
	  checkbox.checked = true; // Check the checkbox
	  //console.log(`Matching item found: ${checkboxValue}`);
	}
  });

		
		this.setState(
			{
				Country: Country , 
				categoryname: categoryname,
				DegreeType: DegreeType,
			},
			() => this.filter()
		);

		const pathKey = getParameterByName("key");
		
		
		

		this.filter();
		if(pathKey == "UniSmarter") {
			document.querySelector('input[value="Undergraduate"]').click();
		}
		this.filterbyKeywordURI();
		// var programList = this.state.data;
		//   console.log("PROGRAMlIST",programList);
		//   var state = this.state;
		// ["Country", "DegreeType"].forEach(function (filterBy) {
		//     console.log("FILTER_BY", filterBy)
		//     var filterValue = state[filterBy];
		//     //filterValue=>value or sortValue1/sortValue2
		//     console.log("sortValue1/sortValue2", filterValue)
		//     if (filterValue) {
		//         programList = programList.filter(function (item) {
		//             return item[filterBy] === filterValue;
		//         });
		//     }
		// })
		// console.log("ProgramList2",programList)
		// this.setState({FilterdList : programList})
		// console.log("FilterdList3",this.state.FilterdList)
	}
	// countryprogramfiltering=(e)=>
	// {
	// //  console.log("sharan",e.target.value);
	//  e.preventDefault();
	//  if (e.target.value !== null || e.target.value !== "null")
	//    this.setState({ sortValue: e.target.value });
	// }
	// categorylistfiltering=(e)=>{
	//   // console.log("sharan",e.target.value);
	//   e.preventDefault();
	//   if (e.target.value !== null || e.target.value !== "null")
	//    this.setState({ sortValue: e.target.value });
	//  }
	// graduationlistfiltering=(e)=>{
	//   // console.log("sharan",e.target.value);
	//   e.preventDefault();
	//   if (e.target.value !== null || e.target.value !== "null" || e.target.value === "PG")
	//     {this.setState({ sortValue1: "7" });}
	//     else if (e.target.value !== null || e.target.value !== "null" || e.target.value === "UG")
	//   {this.setState({ sortValue1: "6"});}
	//  }

	
	ProgramInfoCommonUGList = (node) => {
		// const node = this.props.data.allWordpressWpPogramlist.edges[0];
		return (
			<ProgramInfoCommon
				DetailPageUrl={
				node.third_party_link
					? node.third_party_link
					: `/program-detail/${node.Country.replace(/\s+/g, "-").toLowerCase()}/${_.kebabCase(node.UniversityNameUrl)}/ug/${_.kebabCase(node.slug)}/`
				}
				Target={node.third_party_link ? `_blank`: `_self`}
				UniversityImage={node.UniversityImage}
				UniversityRank={node.UniversityRank}
				UniversityLogo={node.UniversityLogo}
				ProgramName={node.title}
				PogramDuration={node.PogramDuration}
				Country={node.Country}
				UniversityName={node.UniversityName}
				ShortEligibility={node.ShortEligibility}
				YouSave={node.YouSave}
				Term1Info={node.Term1Info}
				Term2Info={node.Term2Info}
				Term3Info={node.Term3Info}
				Term1Title={node.Term1Title}
				Term2Title={node.Term2Title}
				Term3Title={node.Term3Title}
				isFeatured={node.Featured}
				featuredOrder={node.FeaturedOrder}
				wordpress_id={node.wordpressid}
		 	/>
				
		);
	};

	ProgramInfoCommonPGList = (node) => {
		// const node = this.props.data.allWordpressWpPogramlist.edges[0].node;
		return (
			<ProgramInfoCommon
				DetailPageUrl={
					node.third_party_link
					? node.third_party_link
					: `/program-detail/${node.Country.replace(/\s+/g, "-").toLowerCase()}/${_.kebabCase(node.UniversityNameUrl)}/pg/${_.kebabCase(node.slug)}/`
				}
				Target={node.third_party_link ? `_blank`: `_self`}
				UniversityImage={node.UniversityImage}
				UniversityRank={node.UniversityRank}
				UniversityLogo={node.UniversityLogo}
				ProgramName={node.title}
				PogramDuration={node.PogramDuration}
				Country={node.Country}
				UniversityName={node.UniversityName}
				ShortEligibility={node.ShortEligibility}
				YouSave={node.YouSave}
				Term1Info={node.Term1Info}
				Term2Info={node.Term2Info}
				Term3Info={node.Term3Info}
				Term1Title={node.Term1Title}
				Term2Title={node.Term2Title}
				Term3Title={node.Term3Title}
				isFeatured={node.Featured}
				featuredOrder={node.FeaturedOrder}
				wordpress_id={node.wordpressid}
			/>
				
		);
	};



	getQueryVariable = (variable) => {
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			if (pair[0] == variable) {
				return pair[1];
			}
		}
		return false;
	};
	filterbycountry = (e) => {


		if (e.target.value !== null || e.target.value !== "null")
			if (this.state.categoryname !== "all") {
				// this.setState({ Country: e.target.value });
				// this.filter();

				// this.setState(
				//   { Country: e.target.value , DegreeType:"all", categoryname:"all" },
				//   () => this.filter()
				// );
				this.setState({ Country: e.target.value, DegreeType: "all", categoryname: "all" }, () => this.filter());
				// this.ProgramInfoUSAUGList();
			} else {
				this.setState({ Country: e.target.value, DegreeType: "all", categoryname: "all" }, () => this.filter());
			}
			
			scrollToTop()

	};
	filterbyUniversity = (e) => {
		
		if (e.target.value !== null || e.target.value !== "null")
	
				
				this.setState({ UniversityNameUrl: e.target.value}, () => this.filter());
		
	};
	filterbyKeyword = (e) => {
		
		
		if ((e.target.value !== null || e.target.value !== "null")  ) {
		
				
				this.setState({ Keyword: e.target.value}, () => this.filter());
		}
		
	};
	filterbyKeywordURI = () => {
		
		
		if ((getParameterByName("key") !== null &&  getParameterByName("key") !== "null")  ) {
		
				
				this.setState({ Keyword: getParameterByName("key")}, () => this.filter());
		}
		
	};
	sortBy = (e) => {
		
		
		if ((e.target.value !== null || e.target.value !== "null") && e.target.value.length >4 ) {
		
				
				this.setState({ sortBy: e.target.value}, () => this.filter());
		}
		
	};
	filterbydegree = (e) => {
		if (e.target.value !== null || e.target.value !== "null")
			// this.setState({ DegreeType: e.target.value });
			this.setState({ DegreeType: e.target.value }, () => this.filter());
			scrollToTop()
		// this.filter();
	};
	filterbyCategory = (e) => {
		if (e.target.value !== null || e.target.value !== "null")
			// this.setState({ DegreeType: e.target.value });
			this.setState({ categoryname: e.target.value }, () => this.filter());
			scrollToTop()
		// this.filter();
	};
	filter = () => {
		var programList = this.state.data;
		var searchedList = [];
		var state = this.state;
		var keyword = state["Keyword"];
		var sortBy = state["sortBy"];

	
			var arrayCountry = [];
			var arrayUniversity = [];
			var arrayDiscipline = [];
			var arrayProgram = [];
			
			var checkedCountry = document.querySelectorAll('#countrySelector input[type=checkbox]:checked');
			var checkedUniversity = document.querySelectorAll('#universitySelector input[type=checkbox]:checked');
			var checkedDiscipline = document.querySelectorAll('#disciplineSelector input[type=checkbox]:checked');
			var checkedProgram = document.querySelectorAll('#programSelector input[type=checkbox]:checked');

			for (var i = 0; i < checkedCountry.length; i++) {
				arrayCountry.push(checkedCountry[i].value)
			}
			for (var i = 0; i < checkedUniversity.length; i++) {
				arrayUniversity.push(checkedUniversity[i].value)
				
			}
			for (var i = 0; i < checkedDiscipline.length; i++) {
				arrayDiscipline.push(checkedDiscipline[i].value)
				
			}
			for (var i = 0; i < checkedProgram.length; i++) {
				arrayProgram.push(checkedProgram[i].value)
				
			}
		

			//console.log("arrayCountry->"+arrayCountry);
			//console.log("arrayUniversity->"+arrayUniversity);
			//console.log("arrayDiscipline->"+arrayDiscipline);
			//console.log("arrayProgram->"+arrayProgram);
			if(checkedCountry.length>0)
			programList = programList.filter(programList => arrayCountry.includes(programList.Country));
			if(checkedUniversity.length>0)
			programList = programList.filter(programList => arrayUniversity.includes(programList.UniversityNameUrl));
			if(checkedDiscipline.length>0)
			programList = programList.filter(programList => arrayDiscipline.includes(programList.categoryname));
			if(checkedProgram.length>0)
			programList = programList.filter(programList => arrayProgram.includes(programList.DegreeType));



		if(keyword != null && keyword != "" ) {
            keyword = keyword.toLowerCase();
					programList.forEach((program) => {
						var masterString = JSON.stringify(program).toLowerCase(); 
						if(masterString.search(keyword) > 0) {
							searchedList.push(program);
						}
						
					});
				programList  = searchedList;
			}
		


		if(sortBy == "title-desc") {
			multiSort(programList, {
				title: 'desc'
			})

		}else if(sortBy == "title-asc") {
			multiSort(programList, {
				title: 'asc'
			})

		}else if(sortBy == "fees-desc") {
			multiSort(programList, {
				fees: 'desc'
			})

		}else if(sortBy == "fees-asc") {
			multiSort(programList, {
				fees: 'asc'
			})

		}

		
		
		//console.log(this.state.currentGroup);

		
	
		this.setState({ FilterdList: programList, loading: false, currentGroup: 1});
		
		let programCounts = document.getElementsByClassName("program-count");

		let uniqueUniversityNames = [...new Set(programList.map(item => item.UniversityName))];
        
       // console.log(uniqueUniversityNames);

            for (let i = 0; i < programCounts.length; i++) {
                programCounts[i].innerHTML = programList.length +" degrees at " + uniqueUniversityNames.length+"  universities";
            }
			
			this.gotoPageOne();
	
			//this.state.currentGroup == 1;
		// if(programList == 0)
		// alert("No courses found");
		// // console.log("FilterdList3",this.state.FilterdList)
	};

onDisciplineTabChange = (e) => {
		var check = 0;
			const utabHead = e.target.parentNode;
			if (utabHead) {
                const attr = utabHead.attributes.getNamedItem("data-Dis-content");
                if(utabHead.classList.contains("active")){
                    utabHead.classList.remove("active");
                   
                }else{
                    this.removeDisciplinePreviousTabSelection(() => {
                        utabHead.classList.add("active");
                        
                    });
                }
					
			}
			document.querySelector('#result-course-finder').scrollIntoView({ behavior: 'smooth' });
		
	};

removeDisciplinePreviousTabSelection = (fn) => {
		const activeDisTabHead = document.querySelector(".filter-category-parent.active");
		if (activeDisTabHead) {
			activeDisTabHead.classList.remove("active");
		}
		fn();
	};


openMobileFiler = (e) => {
    const mobileFIlterContent = document.getElementById('fiter-course-finder');
    mobileFIlterContent.classList.add("open");
	const mobileFIlterButton = document.getElementById('filter-button-parent');
    mobileFIlterButton.classList.add("open");

};

closeMobileFiler = (e) => {

    const mobileFIlterContent2 = document.getElementById('fiter-course-finder');
    mobileFIlterContent2.classList.remove("open");
	const mobileFIlterButton2 = document.getElementById('filter-button-parent');
    mobileFIlterButton2.classList.remove("open");
};
goToNextPage = (e) => {
	document.querySelector('#result-course-finder').scrollIntoView({ behavior: 'smooth' });
	const currentGroup = parseInt(document.querySelector('.pagination-box').getAttribute('data-current-group'));
	const nextGroup = currentGroup + 1;
  
	const programBoxes = document.querySelectorAll('.program-box-wrapper');
	
	programBoxes.forEach(box => {
	  const dataGroup = parseInt(box.getAttribute('data-group'));
	  if (dataGroup === nextGroup) {
		box.style.display = 'block';
	  } else {
		box.style.display = 'none';
	  }
	});
  //e.logconsole.log(); 
	this.setState({ currentGroup: nextGroup });
	const paginationBox = document.querySelector('.pagination-box');
  	paginationBox.setAttribute('data-current-group', nextGroup);
	  scrollToTop()
  };
  
  gotoPageOne = (e) => {
	this.setState({ currentGroup: 1 }, () => {
		//console.log(this.state.currentGroup);
	
		
	const currentGroup = 0;
	const nextGroup = currentGroup + 1;
	
	const programBoxes = document.querySelectorAll('.program-box-wrapper');
	
	programBoxes.forEach(box => {
	const dataGroup = parseInt(box.getAttribute('data-group'));
	if (dataGroup === nextGroup) {
	box.style.display = 'block';
	} else {
	box.style.display = 'none';
	}
	
	});
	
	this.setState({ currentGroup: nextGroup });
	const paginationBox = document.querySelector('.pagination-box');
	paginationBox.setAttribute('data-current-group', nextGroup);
	
	
		
	  });
}
  

  // Function to go to the previous page
  goToPrevPage = (e) => {
	document.querySelector('#result-course-finder').scrollIntoView({ behavior: 'smooth' });
	const currentGroup = parseInt(document.querySelector('.pagination-box').getAttribute('data-current-group'));
	const nextGroup = currentGroup - 1;
	if(nextGroup < 1){
	}else{
	const programBoxes = document.querySelectorAll('.program-box-wrapper');
	
	programBoxes.forEach(box => {
	  const dataGroup = parseInt(box.getAttribute('data-group'));
	  if (dataGroup === nextGroup) {
		box.style.display = 'block';
	  } else {
		box.style.display = 'none';
	  }
	});
  
	this.setState({ currentGroup: nextGroup });
	const paginationBox = document.querySelector('.pagination-box');
  	paginationBox.setAttribute('data-current-group', nextGroup);
	  scrollToTop()
}
  };

	render() {
		const data = this.props.data;
		const post = this.props.data.allWordpressPage.nodes[0];
		const metatitle = post.acf.meta_title ;
		const metadescription = post.acf.meta_description;
		const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = `https://theworldgrad.com${this.props.path}`; 

const groupTotal = this.state.FilterdList ? Math.floor(this.state.FilterdList.length / 9) + 1 : 0;
		//   var programList = this.state.data;
		//   console.log("PROGRAMlIST",programList);
		//   var state = this.state;
		// ["Country", "DegreeType"].forEach(function (filterBy) {
		//     console.log("FILTER_BY", filterBy)
		//     var filterValue = state[filterBy];
		//     //filterValue=>value or sortValue1/sortValue2
		//     console.log("sortValue1/sortValue2", filterValue)
		//     if (filterValue) {
		//         programList = programList.filter(function (item) {
		//             return item[filterBy] === filterValue;
		//         });
		//     }
		// })
		// console.log("ProgramList2",programList)
		// this.setState({FilterdList : programList})
		// console.log("FilterdList3",this.state.FilterdList)
		// const ProgramInformation = this.props.data.allWordpressWpPogramlist;
		// const locationlist = this.props.data.allWordpressWpLocationlist;
		// const Categorylist = this.props.data.allWordpressCategory;
		// const Graduationlist = this.props.data.allWordpressWpGraduationlist;

		// console.log(post);
		return (
			<div>
				<Layout>
					<Helmet>
					<title>{metatitle}</title>
                    <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          
		</Helmet>
 {/*canonical_url*/}
                 <div class="newStyle">  
				 <div className="container">
					<ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
						<li class="breadcrumb-item">
							<a href="/">Home </a>
						</li>
						<li class="breadcrumb-item active"><a target="_blank">Degree Finder</a></li>
					</ol>
				</div>

                   <section class="wow animate__fadeIn bg-light-gray padding-125px-tb sm-padding-80px-tb position-relative" style={{backgroundImage:"url(https://assets.theworldgrad.com///gatsby-assets/images/Course-finder-banner-NewDesktop.jpg)", backgroundSize:"cover", backgroundPosition:"top"}}>
		<div class="opacity-medium-2 bg-medium-slate-blue"></div>
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-12 col-xl-6 col-lg-7 col-md-8 position-relative  text-center d-flex justify-content-center flex-column">
                        <h1 class="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">Choose The Right Degree For You</h1>
                    </div>
                </div>
            </div>
        </section> 
                                                                                       
                   <section class="half-section blog-left-side-bar position-relative overflow-visible">
        <div class="course-search-box-holder">
            <div class="course-search-box">
                <input type="text" id="lname" name="lname" className="programSortBySalect" onChange={this.filterbyKeyword}  class="programvalues" placeholder="Search for a degree or university" />
                <input type="submit"  value="" />
            </div></div>
            <div id="program-listing-container" class="container">
                <div class="row justify-content-center">
					<aside id="fiter-course-finder" class="col-12 col-xl-3  col-lg-4 col-md-7 lg-padding-4-rem-right md-padding-15px-right">
    <span class="alt-font font-weight-500 text-medium position-relative fiter-course-finder-title margin-25px-bottom text-extra-dark-gray d-block margin-25px-bottom"><strong>Filter results by</strong> <span class="close filter-icon-close" onClick={(e) => this.closeMobileFiler(e)}></span></span>
                                                                                       
    <div id="programSelector" class="d-inline-block w-100 margin-30px-bottom border-bottom border-color-medium-gray">
        <span class="alt-font font-weight-500 text-large text-extra-dark-gray d-block margin-25px-bottom">Program level</span>
        <ul class="list-style-07 filter-category margin-30px-bottom">
		{this.state.listDegree &&
					this.state.listDegree.length &&
					this.state.listDegree?.map((node) => {
						return (
							<li><input type="checkbox" value={node}  onChange={this.filterbydegree} /><span>{node}</span></li>
						);
					})}            
        </ul>
    </div>
                                                                                       
                                                                                       
    <div id="countrySelector" class="d-inline-block w-100 margin-30px-bottom border-bottom border-color-medium-gray">
        <span class="alt-font font-weight-500 text-large text-extra-dark-gray d-block margin-25px-bottom">Country</span>
        <ul class="list-style-07  margin-30px-bottom">
		{this.state.listDegree &&
					this.state.listCountry.length &&
					this.state.listCountry?.map((node) => {
						return (
							<li class="filter-category-parent">
                <input type="checkbox" value={node} onChange={this.filterbycountry}/><span>{node}</span>
            </li>
						);
					})}  

        </ul>
    </div>
	   					
    
	   					

										<div id="disciplineSelector" class="d-inline-block w-100 margin-30px-bottom border-bottom border-color-medium-gray">
											<span class="alt-font font-weight-500 text-large text-extra-dark-gray d-block margin-25px-bottom">Discipline</span>
											<ul class="list-style-07 filter-category margin-30px-bottom">
												{this.state.listCategory &&
													this.state.listCategory.length &&
													this.state.listCategory?.map((node) => {
														var key = Object.keys(node)[0];
														return (
															<li class="filter-category-parent" data-Dis-content="disSublink1">
																  {(() => {
											
																	if(node[key] != null &&
																		node[key].length >1) {return (<div class="disciplineLink" onClick={(e) => this.onDisciplineTabChange(e)}></div>)} })()}
															
                                                                
																<input type="checkbox" value={Object.keys(node)} onChange={this.filterbyCategory} /><span>{Object.keys(node)}</span>
																<ul id="disSublink1" class="list-style-07 filter-category-clild margin-30px-bottom">
																{node[key] &&
																		node[key].length &&
																		node[key]?.map((subnode) => {
																			if(subnode && subnode != "null" && subnode != "" )

																			return (
																				<li><input type="checkbox" value={subnode} onChange={this.filterbyCategory} /><span>{subnode}</span></li>
																			);
																		})}
																</ul>
															</li>

														);
														
													}
					
					
					)}  
					</ul>
					
            
 

    </div>
				

</aside>
<div id="filter-button-parent">
<a data-animation="animated zoomInUp" id="filter-done-btn" class="filter-done-btn btn btn-theme btn-large effect mt-50" onClick={(e) => this.closeMobileFiler(e)} href="#">DONE</a>
</div>
					
                    <div id="result-course-finder" class="col-12 col-lg-9  left-sidebar md-margin-60px-bottom sm-margin-40px-bottom">
						<div class="row justify-content-center">
							
                            <div class="col-6">
                                <a href="#" class="filter-icon-mobile" onClick={(e) => this.openMobileFiler(e)}><img src="https://assets.theworldgrad.com//gatsby-assets/images/icon-filter.svg" alt="||" /> Filter</a>
								<h4  class="program-count-desktop program-count"></h4>
			

								 
                            </div>
                            <div class="col-6">
                                <div id="product-sort-box" class="blog-filter-holder">
                                    <select className="programSortBySalect" onChange={this.sortBy}>
													<option value="all" class="programvalues">
													Sort By
													</option>
													<option value="title-asc" class="programvalues">
													Course Title A-Z
													</option>
													<option value="title-desc" class="programvalues">
													Course Title Z-A
													</option>
													
	
												</select>
                                    
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
						<div class="row program-count-wrapper">
						<div className="col-12 margin-30px-bottom">
						<h4 class="program-count-mobile program-count "></h4>
						
						</div>
						</div>
                            
						<div class="row program-count-wrapper">
							{/*console.log("Effects Dump -"+ JSON.stringify(this.state.FilterdList))*/}
                           {/* {!this?.state?.loading?this?.state?.FilterdList && this?.state?.FilterdList?.length>0 &&
                                          (
                                            this.state.FilterdList.map( node  => { */}
											
												{!this.state.loading ? (
													this.state.FilterdList &&
													this.state.FilterdList.length > 0 &&
													this.state.FilterdList.map((node, index) => {
														const groupNumber = Math.floor(index / 9) + 1;
														
														// console.log("Id", node.Country, node.GraduationlistId,this.state.sortValue1);
														//  if ( node.Country === this.state.sortValue || this.state.sortValue === null || this.state.sortValue === "NULL" || this.state.sortValue1 === node.GraduationlistId ||  this.state.sortValue1 === null ||
														//    this.state.sortValue1 === "NULL")
														//   {
														//console.log("node-->" + JSON.stringify(node.title));
														
														return (
															<React.Fragment key={node.id}>
																
															<div data-group={groupNumber} className="col-sm-6 col-12 col-lg-4 program-box-wrapper margin-30px-bottom">
																{(node.DegreeType === "Undergraduate" ? this.ProgramInfoCommonUGList(node) : this.ProgramInfoCommonPGList(node))}
															</div>
															
															</React.Fragment>
														);
														
														// }
													})
												) : (
													<h1>Loading the best programs for you...</h1>
												)}
<div class={`pagination-box ${this.state.currentGroup == groupTotal ? "hide-next-link" : ""}`}  data-current-group={this.state.currentGroup} data-totla-group={groupTotal}>
<a onClick={this.goToPrevPage} class="pagination-link prev-page-link" ><img src={ArrowLeftSolidIcon} alt="<-" className="left-icon newicon newicon-black " />  Go to Page <span>{this.state.currentGroup - 1}</span></a>
<a onClick={this.goToNextPage} class="pagination-link next-page-link">Go to Page <span>{this.state.currentGroup + 1}</span> <img src={ArrowRightSolidIcon} alt="->" className="left-icon newicon newicon-black " /> </a>
</div>
               			</div>
                    </div>
                </div>
            </div>
        </section> </div>
					
				</Layout>
			</div>
		);
	}
}

export default Programlisting;

export const programlistnew = graphql`
	query programlistnew {
		allWordpressPage(filter: {wordpress_id: {in: 4929}}) {
            nodes {
              title
              wordpress_id
              acf {
                 
                meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema
               
          }
        }
    }
	
	}
`;